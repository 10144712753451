import React, { useEffect, useState } from "react";

import {
  AllTableList,
  DownloadButtonDiv,
  HeaderComponent,
  IconFiEdit,
  IconHiUser,
  IconSpace,
  TextH2,
} from "../../../styles/global.styles";
import { ICustomer } from "../../../Services/Interfaces";
import { BuscarIconDiv, CustomerDiv, DetalhesIconDiv } from "./styles";
import PaginationMUI, { PaginationProps } from "../../../Components/Pagination";
import ModalComponentCustomer from "../../../Components/Modal/Customer";
import { cpfCnpjMask } from "../../../utils/conversions";
import { useNavigate } from "react-router-dom";
import {
  CustomerFormControl,
  CustomerInputLabel,
  CustomerMenuItem,
  CustomerOutlinedInput,
  CustomerSelect,
} from "../../../Components/CustomerEdit/styles";
import { FiDownload } from "react-icons/fi";
import { exportCostumers } from "../../../Services/Admin";
import { AxiosResponse } from "axios";

interface PageCustomersProps {
  customers: ICustomer[];
  pagination: PaginationProps;
  setLikeSearch: React.Dispatch<React.SetStateAction<string>>;
  setStatusSearch: React.Dispatch<React.SetStateAction<string>>;
}

const PageCustomersAdminConteudo: React.FC<PageCustomersProps> = ({
  customers,
  pagination,
  setLikeSearch,
  setStatusSearch,
}) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<{ id: string; name: string }>({
    id: "Todos",
    name: "Todos",
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [search, setSearch] = useState<string>("");
  const locationHref = async (customers: ICustomer) => {
    setIsOpen(!modalIsOpen);
    setCustomer(customers);
  };

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  useEffect(() => {
    if (status.id === "Todos") {
      setStatusSearch("");
    } else if (status.id === "true") {
      setStatusSearch("true");
    } else if (status.id === "false") {
      setStatusSearch("false");
    }
  }, [setStatusSearch, status]);

  const downloadCostumers = async () => {
    await exportCostumers(status.id , search).then((response) => {
      const dow = response as AxiosResponse<Blob>;
      if (dow.status === 200) {
        const downloadUrl = window.URL.createObjectURL(dow.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `exportClientes-${new Date().getTime()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  return (
    <CustomerDiv>
      <HeaderComponent>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TextH2>Gestão de Clientes</TextH2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DownloadButtonDiv onClick={downloadCostumers}>
            <FiDownload
              style={{
                marginRight: "5px",
              }}
            />
            Exportar
          </DownloadButtonDiv>
        </div>
      </HeaderComponent>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomerFormControl variant="outlined">
          <CustomerInputLabel htmlFor="profileFullName">
            Texto da busca
          </CustomerInputLabel>
          <CustomerOutlinedInput
            value={search}
            onChange={(e) => setSearch(e.target.value.replace(/[./-]/g, ''))}
            id="profileFullName"
            type="text"
            label="Texto da busca"
            onKeyDown={(e) => {
              if (e.key === "Enter") setLikeSearch(search);
            }}
          />
        </CustomerFormControl>
        <CustomerFormControl percentage={"10%"} variant="outlined">
          <CustomerInputLabel htmlFor="tipo">TIPO</CustomerInputLabel>
          <CustomerSelect
            value={status.id}
            onChange={(e) => {
              setStatus({
                id: e.target.value as string,
                name: e.target.value as string,
              });
            }}
            id="status"
            label="Status"
          >
            <CustomerMenuItem key={"Todos"} value={"Todos"}>
              Todos
            </CustomerMenuItem>
            <CustomerMenuItem key={"true"} value={"true"}>
              Ativo
            </CustomerMenuItem>
            <CustomerMenuItem key={"false"} value={"false"}>
              Inativo
            </CustomerMenuItem>
          </CustomerSelect>
        </CustomerFormControl>
        <BuscarIconDiv
          onClick={() => {
            setLikeSearch(search);
          }}
        >
          Buscar
        </BuscarIconDiv>
      </div>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>Nome</th>
              <th>Empresa</th>
              <th>Código do Cliente</th>
              <th>CNPJ/CPF</th>
              <th>STATUS</th>
              <th>Medições Contratadas</th>
              <th>Detalhes</th>
            </tr>
            {customers?.map((customer) => {
              if (customer.customer) {
                return customer.customer.customerServices?.map(
                  (services, index) => {
                    if (index <= 0) {
                      return (
                        <tr key={customer.id + index}>
                          <th
                            rowSpan={
                              customer.customer?.customerServices.length || 1
                            }
                          >
                            <IconHiUser />
                            {customer.profile?.fullName}
                          </th>
                          <td>{services.service.healthPlace.name || "-"}</td>
                          <td>{customer.userCode  || ""}</td>
                          <td>
                            {customer.profile?.cpf
                              ? cpfCnpjMask(customer.profile?.cpf)
                              : "-"}
                          </td>
                          <td>{services.isActive ? "Ativo" : "Inativo"}</td>
                          <td>{services.limitPerMonth || "-"}</td>
                          <td
                            rowSpan={
                              customer.customer?.customerServices.length || 1
                            }
                          >
                            <DetalhesIconDiv
                              onClick={() => {
                                locationHref(customer);
                              }}
                            >
                              Detalhes
                            </DetalhesIconDiv>
                          </td>
                          <td>
                            <IconSpace>
                              <IconFiEdit
                                onClick={() => {
                                  Rota(`/admin/gestao/clientes/${customer.id}`);
                                }}
                                size={20}
                              />
                            </IconSpace>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={customer.id + index}>
                          <td>{services.service.healthPlace.name || ""}</td>
                          <td>{customer.userCode  || ""}</td>
                          <td>
                            {customer.profile?.cpf
                              ? cpfCnpjMask(customer.profile?.cpf)
                              : "-"}
                          </td>
                          <td>{services.isActive ? "Ativo" : "Inativo"}</td>
                          <td>{services.limitPerMonth || ""}</td>
                        </tr>
                      );
                    }
                  }
                );
              } else {
                return (
                  <tr key={customer.id}>
                    <th rowSpan={1}>
                      <IconHiUser />
                      {customer.profile?.fullName}
                    </th>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td rowSpan={1}>
                      <DetalhesIconDiv
                        onClick={() => {
                          locationHref(customer);
                        }}
                      >
                        Detalhes
                      </DetalhesIconDiv>
                      <DetalhesIconDiv
                        onClick={() => {
                          window.location.href = `/${customer.id}`;
                        }}
                      >
                        Editar
                      </DetalhesIconDiv>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </AllTableList>
      <PaginationMUI
        setLimit={pagination.setLimit}
        setOffset={pagination.setOffset}
        setRowsPerPage={pagination.setRowsPerPage}
        rowsPerPage={pagination.rowsPerPage}
        total={pagination.total}
        setPage={pagination.setPage}
        page={pagination.page}
      />
      {customer && (
        <ModalComponentCustomer
          customer={customer}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
      )}
    </CustomerDiv>
  );
};

export default PageCustomersAdminConteudo;
