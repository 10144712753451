import React, { ChangeEvent, FormEvent, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FaUpload } from "react-icons/fa";
import { customerImport } from "../../Services/User";
import { CenteredPageDiv } from "../../styles/global.styles";
import {
  AccountButton,
  AccountContentButton,
  AccountDiv,
  AccountDownloadA,
  AccountDownloadButton,
  AccountForm,
  AccountInputLabel,
  AccountOutlinedInputFile,
  AccountPBlack,
  TextH2,
} from "./styles";

const CustomerRegistrationComponent: React.FC<any> = () => {
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setFile(files[0]);
    } else {
      setFile(undefined);
    }
  };

  const handleUpload = async (e: FormEvent) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("import[file][file]", file, file.name);
      await customerImport(formData).then(() => {
        setTimeout(() => {
          return window.location.reload();
        }, 5000);
      });
    }
  };

  return (
    <CenteredPageDiv>
      <TextH2>Cadastro/Atualização Clientes</TextH2>
      <br />
      <AccountDownloadA href="/UsuariosProsperaSaude.xlsx">
        <AccountContentButton>
          <AccountDownloadButton title="Download modelo planilha">
            Download modelo planilha
          </AccountDownloadButton>
        </AccountContentButton>
      </AccountDownloadA>
      <br />

      <AccountForm onSubmit={handleUpload}>
        <AccountDiv percentage={"100%"}>
          <AccountInputLabel htmlFor="file">
            {!file && (
              <div style={{ textAlignLast: "center" }}>
                <FiPlus size={36} />
                <br />
                Fazer upload do arquivo
              </div>
            )}
            {file && <div style={{ textAlignLast: "center" }}>{file.name}</div>}
          </AccountInputLabel>

          <AccountOutlinedInputFile
            onChange={handleFileChange}
            id="file"
            type="file"
            label="File"
          />
        </AccountDiv>
        <AccountPBlack>
          Clique para selecionar o arquivo .xlsx ou arraste o arquivo para
          dentro do box
        </AccountPBlack>
        <AccountContentButton>
          <AccountButton title="Salvar">
            Enviar <FaUpload />
          </AccountButton>
        </AccountContentButton>
      </AccountForm>
    </CenteredPageDiv>
  );
};

export default CustomerRegistrationComponent;
