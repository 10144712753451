import React, { ReactElement, useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";

import logoProspera from "../../../Images/logo-Prospera.png";
import logoProsperaBranco from "../../../Images/logo-Prospera-branco.png";
import {
  AlignCenter,
  AvatarContainer,
  AvatarTextoSpan,
  CloseSidebar,
  ComponentContent,
  CopyrightContainer,
  Header,
  HeaderContent,
  LinkA,
  LogoMenu,
  MenuButton,
  MenuContainer,
  MenuContainerHeader,
  MenuContainerSection,
  MenuMobile,
  MenuMobileClose,
  HideItemDesk,
  HideItemMobile,
  Sidebar,
  SidebarHeaderContainer,
  SidebarMenu,
  TextoA,
} from "./styles";
import { Logout } from "../../../Components/Auth/isAuthenticated";
import { useNavigate } from "react-router-dom";
import { IHealthPlace, IUserLogado } from "../../../Services/Interfaces";
import ModalComponentMedicao from "../../../Components/Modal/Medicao";
import { exportDumpDB } from "../../../Services/Admin";
import { AxiosResponse } from "axios";
import { useTheme } from "@emotion/react";
import { getColorByBackground } from "../../../utils/conversions";
import ModalComponentExportReport from "../../../Components/Modal/ExportReport";

interface PageHeaderProps {
  InternalComponent: ReactElement<any, any>;
}

const PageHeaderUser: React.FC<PageHeaderProps> = ({ InternalComponent }) => {
  const theme = useTheme() as any;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [userLogado, setUserLogado] = useState<IUserLogado>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isMenuAdmin, setIsMenuAdmin] = useState(false);
  const [healthPlaceLogo, setHealthPlaceLogo] = useState<string>();
  const [healthPlace, setHealthPlace] = useState<IHealthPlace>();
  const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false);

  const locationHref = async () => {
    setOpen(!open);
    setIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    const urlHealthPlaceLogo = localStorage.getItem("HealthPlaceLogo");
    if (urlHealthPlaceLogo) {
      setHealthPlaceLogo(
        `${
          process.env.REACT_APP_API_URL ||
          "https://api.sinaisvitais.prosperasaude.com.br"
        }${urlHealthPlaceLogo}`
      );
    }

    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const userObjeto = JSON.parse(userJSON) as IUserLogado;
      setUserLogado(userObjeto);
      if ([null, false].includes(userObjeto.profile.usageTerms)) {
        return navigate("/policy", { replace: true });
      } else if (window.location.pathname.indexOf("/admin/") !== -1) {
        setIsMenuAdmin(true);
      } else {
        setIsMenuAdmin(false);
      }
    }
  }, [navigate]);

  useEffect(() => {
    const healthPlaceJSON = localStorage.getItem("HealthPlaceDados");
    if (healthPlaceJSON) {
      const healthPlaceObjeto = JSON.parse(healthPlaceJSON) as IHealthPlace;
      setHealthPlace(healthPlaceObjeto);
    }
  }, []);

  const LogOut = () => {
    Logout();
    return navigate("/login", { replace: true });
  };

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const downloadDumpDB = async () => {
    await exportDumpDB().then((response) => {
      const dow = response as AxiosResponse<Blob>;
      if (dow.status === 200) {
        const downloadUrl = window.URL.createObjectURL(dow.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `DumpDB-${new Date().getTime()}.sql.gz`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  return (
    <>
      {userLogado && (
        <SidebarHeaderContainer
          firstBackgroundColor={theme.palette.primary.main}
          secondBackgroundColor={theme.palette.secondary.main}
        >
          <Sidebar
            firstBackgroundColor={theme.palette.primary.main}
            secondBackgroundColor={theme.palette.secondary.main}
            style={{
              transition: "ease 0.4s",
            }}
            className={open ? "opened" : ""}
          >
            <SidebarMenu>
              <CloseSidebar onClick={() => setOpen(!open)}>
                <TfiClose />
              </CloseSidebar>
              <HideItemMobile>
                <LogoMenu
                  onClick={() => Rota("/home")}
                  src={healthPlaceLogo ? healthPlaceLogo : logoProsperaBranco}
                  alt="Prospera"
                />
              </HideItemMobile>
              <AlignCenter>
                {/* TODO: Ajustar url */}
                <AvatarContainer
                  src={`${
                    userLogado.profile.profilePicture === null
                      ? "https://static.vecteezy.com/ti/vetor-gratis/p1/5594107-modelo-de-terno-de-homem-da-moda-para-web-vetor.jpg"
                      : `${
                          process.env.REACT_APP_API_URL ||
                          "https://api.sinaisvitais.prosperasaude.com.br"
                        }${userLogado.profile.profilePicture}`
                  }`}
                  alt={`${userLogado.profile.fullName}`}
                />
                <AvatarTextoSpan>{`${userLogado.profile.fullName}`}</AvatarTextoSpan>
              </AlignCenter>
              <MenuContainer>
                {!isMenuAdmin && (
                  <>
                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/home")}
                        >
                          Home
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>

                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/minha-conta")}
                        >
                          Minha Conta
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>

                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/medicoes")}
                        >
                          Medições
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>
                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/purchase")}
                        >
                          Minha Compra
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>
                  </>
                )}
                {isMenuAdmin && (
                  <>
                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/admin/gestao/empresas")}
                        >
                          Gestão de Empresas
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>

                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/admin/gestao/programas")}
                        >
                          Gestão de Programas
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>

                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/admin/gestao/clientes")}
                        >
                          Gestão de Clientes
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>

                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => Rota("/admin/gestao/clientes/new")}
                        >
                          Cadastro/Atualização Clientes
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>
                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <ModalComponentExportReport
                          setIsOpen={setIsExportReportModalOpen}
                          isOpen={isExportReportModalOpen}
                        />
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={() => setIsExportReportModalOpen(true)}
                        >
                          Exportar Relatórios
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>
                    <MenuContainerSection>
                      <MenuContainerHeader>
                        <TextoA
                          primary={theme.palette.text.primary}
                          onClick={downloadDumpDB}
                        >
                          BD Dump
                        </TextoA>
                      </MenuContainerHeader>
                    </MenuContainerSection>
                  </>
                )}
              </MenuContainer>
            </SidebarMenu>
            <HideItemDesk>
              <ModalComponentMedicao
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}
              />
              <MenuButton
                id="medicao"
                onClick={() => {
                  if (
                    healthPlace &&
                    healthPlace.limitPerMonth >= healthPlace.usedCurrentMonth
                  ) {
                    locationHref();
                  }
                }}
              >
                {healthPlace &&
                healthPlace.limitPerMonth >= healthPlace.usedCurrentMonth
                  ? "Iniciar nova medição"
                  : `${healthPlace?.limitPerMonth}Você já realizou todas as medições disponíveis`}
              </MenuButton>
            </HideItemDesk>
            <CopyrightContainer>
              <LinkA primary={theme.palette.text.primary} onClick={LogOut}>
                Sair
              </LinkA>
              <br></br>
              {isMenuAdmin ? (
                <LinkA
                  primary={theme.palette.text.primary}
                  onClick={() => Rota("/minha-conta")}
                >
                  Minha Conta
                </LinkA>
              ) : (
                <LinkA
                  primary={theme.palette.text.primary}
                  onClick={() => Rota("/admin/gestao/empresas")}
                >
                  Área Admin
                </LinkA>
              )}
              <br></br>
              <LinkA
                primary={theme.palette.text.primary}
                onClick={() => Rota("/policy")}
              >
                Termo de Uso e <br />
                Política de Proteção <br />
                de Dados e Privacidade
              </LinkA>
            </CopyrightContainer>
          </Sidebar>
          <HeaderContent>
            <Header>
              <MenuMobile>
                <MenuMobileClose onClick={() => setOpen(!open)}>
                  <AiOutlineMenu
                    color={getColorByBackground(theme.palette.primary.main)}
                    size={20}
                  />
                </MenuMobileClose>
                <LogoMenu
                  src={healthPlaceLogo ? healthPlaceLogo : logoProspera}
                  alt="Prospera"
                />
                <div></div>
                <div></div>
              </MenuMobile>
              <HideItemMobile>
                <AvatarContainer
                  onClick={() => Rota("/minha-conta")}
                  src={`${
                    userLogado.profile.profilePicture === null
                      ? "https://static.vecteezy.com/ti/vetor-gratis/p1/5594107-modelo-de-terno-de-homem-da-moda-para-web-vetor.jpg"
                      : `${
                          process.env.REACT_APP_API_URL ||
                          "https://api.sinaisvitais.prosperasaude.com.br"
                        }${userLogado.profile.profilePicture}`
                  }`}
                  alt={`${userLogado.profile.fullName}`}
                />
              </HideItemMobile>
            </Header>
            <ComponentContent>{InternalComponent}</ComponentContent>
          </HeaderContent>
        </SidebarHeaderContainer>
      )}
    </>
  );
};

export default PageHeaderUser;
